import { gql } from "@apollo/client"
import {
  getHouseholdMainTownOfTour,
  getHouseholdMainTownOfTour_getHouseholdMainTownOfTour,
  getHouseholdMainTownOfTourVariables,
} from "./types/getHouseholdMainTownOfTour"

export const GET_HOUSEHOLD_MAIN_TOWN__OF_TOUR_QUERY = gql`
  query getHouseholdMainTownOfTour($tourId: Int!) {
    getHouseholdMainTownOfTour(tourId: $tourId) {
      export_format
    }
  }
`

export type HouseholdMainTownOfTourResult = getHouseholdMainTownOfTour
export type HouseholdMainTownOfTourVariables = getHouseholdMainTownOfTourVariables
export type HouseholdMainTownOfTour = getHouseholdMainTownOfTour_getHouseholdMainTownOfTour