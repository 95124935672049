import React, { useContext } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { HouseholdContainerContext } from "../context/household-container-context"

interface IHouseholdContainerEditDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const HouseholdContainerEditDialog: React.FC<IHouseholdContainerEditDialogProps> = (props) => {
  const { t } = useTranslation()
  const {
    materialOptions,
    typeOptions,
    formValues,
    optionsLoading,
    optionsError,
    updateLoading,
    handleChange,
    onConfirm,
  } = useContext(HouseholdContainerContext)

  const { open, setOpen } = props

  if (optionsLoading) return <CircularProgress />
  if (optionsError) return

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>{t("household_details.edit_container")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" sx={{ p: 2 }} spacing={2}>
          <Typography variant="body1" sx={{ color: "gray", fontWeight: 500 }}>
            {t("household_details.household")}
          </Typography>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={t("household_details.street")}
              value={formValues?.street ?? ""}
              onChange={(e) => handleChange("street", e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={t("household_details.number")}
              value={formValues?.number ?? ""}
              onChange={(e) => handleChange("number", e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={t("household_details.postal")}
              value={formValues?.postal ?? ""}
              onChange={(e) => handleChange("postal", e.target.value)}
              inputProps={{ maxLength: 6, inputMode: "numeric", pattern: "[0-9]*" }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={t("household_details.place")}
              value={formValues?.place ?? ""}
              onChange={(e) => handleChange("place", e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" sx={{ p: 2 }} spacing={2}>
          <Typography variant="body1" sx={{ color: "gray", fontWeight: 500 }}>
            {t("household_details.container")}
          </Typography>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={t("household_details.rfid")}
              value={formValues?.rfid ?? ""}
              onChange={(e) => handleChange("rfid", e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item>
            <OutlinedSelect
              options={materialOptions}
              name={t("household_details.material")}
              onValueChange={(newValue) => handleChange("material", newValue)}
              value={formValues?.material ?? ""}
            />
          </Grid>
          <Grid item>
            <OutlinedSelect
              options={typeOptions}
              name={t("household_details.type")}
              onValueChange={(newValue) => handleChange("type", newValue)}
              value={formValues?.type ?? ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm()
            setOpen(false)
          }}
          color={"primary"}
          variant="contained"
        >
          {updateLoading ? <CircularProgress /> : t("household_details.edit_dialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
