import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Backdrop, Button, Card, CircularProgress, Divider, Grid, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import lodash from "lodash"
import moment from "moment"
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AlgorithmType, UserRole } from "../../../../api/graphql/graphql-global-types"
import {
  GenerateToursResult,
  GenerateToursVariables,
  GENERATE_TOURS_MUTATION,
} from "../../../../api/graphql/mutations/generate-tours"
import {
  ConcurrentTourParametersResult,
  ConcurrentTourParametersVariables,
  CONCURRENT_TOUR_PARAMETERS_QUERY,
} from "../../../../api/graphql/queries/concurrent-tour-parameters"
import {
  TourExportPreviewResult,
  TourExportPreviewVariables,
  TOUR_EXPORT_PREVIEW_QUERY,
} from "../../../../api/graphql/queries/tour-export-preview"
import { useRefetch } from "../../../../context/refetch-context"
import { TourGenerationContext, TourGenerationSelectValue } from "../../../../context/tour-generation-context"
import { UserContext } from "../../../../context/user-context"
import { UserGroups } from "../../../../models/user-groups"
import { DayOfWeek, dayOfWeekToNumber, numberToDay, sortByDayOfWeek } from "../../../../utils/day"
import { isVehicleActive } from "../../../../utils/tourGeneration"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { InfoDialog } from "../../../partials/layout/dialog/info-dialog"
import { ConcurrentTourGenerationsDialog } from "./concurrent-tour-generations-dialog"
import { ConfirmTourGenerationDialog } from "./confirm-tour-generation-dialog"
import { TourGenerationDisposalMerchantAvailabiltyDialog } from "./tour-generation-disposal-merchant-availability-dialog"
import { TourGenerationForm } from "./tour-generation-form"
import { TourGenerationVehicleSelection } from "./tour-generation-vehicle-selection"
import ErrorOutline from "@mui/icons-material/ErrorOutline"
import CheckCircle from "@mui/icons-material/CheckCircleOutline"
import { PATH } from "../../../../router/router"
import { useNavigate } from "react-router-dom"
import { STORE_KEYS } from "../../../../services/user-service"
import store from "store"

interface ITourGenerationDataProps {}

export const TourGenerationData: FunctionComponent<ITourGenerationDataProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { setNeedToRefetch } = useRefetch()
  const { user } = useContext(UserContext)
  const [confirmTourGenerationDialogOpen, setConfirmTourGenerationDialogOpen] = useState<boolean>(false)
  const [generationFailedDialogOpen, setGenerationFailedDialogOpen] = useState<boolean>(false)
  const [generationSuccessfullDialogOpen, setGenerationSuccessfullDialogOpen] = useState<boolean>(false)
  const [tourParamId, setTourParamId] = useState<string | null | undefined>(null)

  const {
    district,
    towns,
    firstWeek,
    setFirstWeek,
    secondWeek,
    setSecondWeek,
    threshold,
    setThreshold,
    thresholdMin,
    vehicles,
    materials,
    version,
    setScheduleDate,
    maxTerminationTimeValid,
    referenceDateValid,
    referenceDate,
    tourWithoutContainers,
    getGenerateToursVariables,
    getConcurrentTourGenerationVariables,
    disposalMerchants,
    loadingDisposalMerchants,
    disposalTrailerLocations,
    vehicleDaysInput,
    visibleSelect,
    collectionGroup,
  } = useContext(TourGenerationContext)

  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)
  const [notEnabledDialogOpen, setNotEnabledDialogOpen] = useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [concurrentGenerationsOpen, setConcurrentGenerationsOpen] = useState<boolean>(false)
  const [merchantAvailabilityDialog, setMerchantAvailabilityDialog] = useState<boolean>(false)
  const [scheduled, setScheduled] = useState<boolean>(false)

  const navigate = useNavigate()

  const { data, loading } = useQuery<TourExportPreviewResult, TourExportPreviewVariables>(TOUR_EXPORT_PREVIEW_QUERY, {
    variables: {
      district_id: String(district?.id),
      start_date: firstWeek.clone().add(firstWeek.utcOffset(), "minute"),
      threshold: Number(threshold),
      material_ids: materials.map((m) => m.id),
      threshold_min: Number(thresholdMin),
      week_count:
        version === AlgorithmType.AGR ? secondWeek.clone().utc().diff(firstWeek.clone().utc(), "weeks") : null,
      reference_date:
        version !== AlgorithmType.AGR ? referenceDate?.clone().add(referenceDate.utcOffset(), "minute") : null,
      version,
      town_ids: towns ? towns.map((town) => town.id.toString()) : [],
      tour_without_containers: tourWithoutContainers,
      vehicles: vehicleDaysInput,
      collection_group_id: collectionGroup?.id ?? null,
    },
    skip: !confirmTourGenerationDialogOpen,
    onCompleted: ({ tourExportPreview }) => {
      if (tourExportPreview.noRecycles) {
        toast.error(t("tour_generation.data.could_not_generate_no_recyclers"))
      }
    },
  })

  const [generateTours, { loading: generateToursLoading }] = useMutation<GenerateToursResult, GenerateToursVariables>(
    GENERATE_TOURS_MUTATION,
  )

  const onConcurrentTourGenerationsRequestFinished = () => {
    if (concurrentGenerationsData?.concurrentTourParameters.length !== 0) {
      setConcurrentGenerationsOpen(true)
    } else {
      setConfirmTourGenerationDialogOpen(true)
    }
  }

  const [getConcurrentTourGenerations, { loading: concurrentGenerationsLoading, data: concurrentGenerationsData }] =
    useLazyQuery<ConcurrentTourParametersResult, ConcurrentTourParametersVariables>(CONCURRENT_TOUR_PARAMETERS_QUERY, {
      onCompleted: onConcurrentTourGenerationsRequestFinished,
    })

  const resetValues = () => {
    const now = moment()
    setFirstWeek(moment(now).startOf("week"))
    setSecondWeek(moment(now).startOf("week").add(1, "week"))
    setThreshold(80)
    setScheduleDate(null)
  }

  const onConfirmGenerateTours = async (scheduled?: boolean) => {
    setScheduled(scheduled || false)
    if(scheduled || district?.algorithm !== AlgorithmType.VROOM){
      setConfirmTourGenerationDialogOpen(false)
    }
    
    if(!scheduled && district?.algorithm !== AlgorithmType.VROOM){
      toast.info(t("tour_generation.data.tour_is_being_generated"))
    }

    try {
      const res = await generateTours({
        variables: getGenerateToursVariables(data?.tourExportPreview.collectionPoints || []),
      })
      if (
        (res && (res as any).errors && (res as any).errors.length > 0) ||
        res?.data?.generateTours?.error === "true"
      ) {
        toast.error(res.data?.generateTours.message)
        throw new Error()
      }

      if(!scheduled && district?.algorithm === AlgorithmType.VROOM){
        setTourParamId(res.data?.generateTours.message)
        setGenerationSuccessfullDialogOpen(true)
      } else if(!scheduled && district?.algorithm !== AlgorithmType.VROOM){
        toast.info(t("tour_generation.data.tour_generation_successfully"))
      } else if(scheduled){
        toast.info(t("tour_generation.data.tour_is_scheduled"))
      }
      
      resetValues()
    } catch (e) {

      if(!scheduled && district?.algorithm === AlgorithmType.VROOM){
        setGenerationFailedDialogOpen(true)
      }else {
        toast.error(t("tour_generation.data.could_not_generate"))
      }
    }
    setNeedToRefetch(true)
  }

  const hasPermissions =
    (user?.slocUser.enable_tour_export && UserGroups.TOUR_MANAGEMENT.includes(user?.role)) ||
    user?.role === UserRole.SUPER_ADMIN

  const onClickGenerateTours = (skipDateCheck: boolean) => {
    if (!hasPermissions) {
      setNotEnabledDialogOpen(true)
      return
    }
    // selected vehicles
    const activeVehicles = vehicles.filter((vehicle) => isVehicleActive(vehicle, version))

    const selectedDays = lodash.uniq(activeVehicles.flatMap((vehicle) => vehicle.days.map((d) => d.day)))
    const disposalMerchantsOnSelectedDays = disposalMerchants.filter((merchant) =>
      selectedDays.includes(numberToDay(moment(merchant.day).isoWeekday())),
    )

    const isAnyDayWithoutAvailableDisposalMerchant = disposalMerchantsOnSelectedDays.some(
      (day) => day.disposalMerchants.length === 0,
    )
    if (isAnyDayWithoutAvailableDisposalMerchant) {
      setMerchantAvailabilityDialog(true)
      return
    }

    const someMaterialInvalid = activeVehicles.some((vehicle) =>
      vehicle.materials.some((material) => material.amount === 0),
    )
    if (someMaterialInvalid) {
      setInfoDialogOpen(true)
      return
    }

    // check if any planned driving day lies in the past
    // show dialog if true
    const isAnyPlannedDayInThePast = activeVehicles.some((vehicle) =>
      vehicle.days.some((dayOfWeek) =>
        firstWeek
          .clone()
          .add(dayOfWeekToNumber(dayOfWeek) - 1, "day")
          .isBefore(moment(), "day"),
      ),
    )

    if (isAnyPlannedDayInThePast && !skipDateCheck) {
      setConfirmOpen(true)
      return
    }

    getConcurrentTourGenerations({ variables: getConcurrentTourGenerationVariables() })
  }

  const handleConfirmConcurrentTourGenerationsDialog = useCallback(() => {
    setConcurrentGenerationsOpen(false)
    setConfirmTourGenerationDialogOpen(true)
  }, [])

  const firstDrivingDay = useMemo<moment.Moment | null>(() => {
    if (!vehicles || !firstWeek) {
      return null
    }

    const allDays: DayOfWeek[] = vehicles.flatMap((vehicle) => vehicle.days).sort(sortByDayOfWeek)

    if (!allDays.length) {
      return null
    }

    return firstWeek.clone().add(dayOfWeekToNumber(allDays[0]) - 1, "day")
  }, [vehicles, firstWeek])

  const disableGenerateTours = useMemo<boolean>(() => {
    if (!tourWithoutContainers) {
      return (
        vehicles.filter((v) => isVehicleActive(v, version)).length === 0 ||
        lodash.isNil(threshold) ||
        materials.length === 0 ||
        !maxTerminationTimeValid ||
        (version !== AlgorithmType.AGR && !referenceDateValid) ||
        (district !== null &&
          district?.allow_tourgeneration_town_multiselect === true &&
          visibleSelect === TourGenerationSelectValue.Town &&
          towns.length === 0) ||
        (district !== null &&
          district?.allow_tourgeneration_group_select === true &&
          visibleSelect === TourGenerationSelectValue.Group &&
          !collectionGroup) ||
        loadingDisposalMerchants ||
        (version === AlgorithmType.TO2TRAILER && disposalTrailerLocations.length === 0) ||
        (version === AlgorithmType.TO2TRAILER &&
          district !== null &&
          district.disposalTrailerLocationLimit !== null &&
          disposalTrailerLocations.length > district.disposalTrailerLocationLimit)
      )
    }
    return (
      vehicles.filter((v) => v.isActive).length === 0 ||
      !maxTerminationTimeValid ||
      (district !== null &&
        district?.allow_tourgeneration_town_multiselect === true &&
        visibleSelect === TourGenerationSelectValue.Town &&
        towns.length === 0) ||
      (district !== null &&
        district?.allow_tourgeneration_group_select === true &&
        visibleSelect === TourGenerationSelectValue.Group &&
        !collectionGroup) ||
      loadingDisposalMerchants
    )
  }, [
    vehicles,
    threshold,
    materials,
    maxTerminationTimeValid,
    referenceDateValid,
    version,
    district,
    towns,
    tourWithoutContainers,
    loadingDisposalMerchants,
    disposalTrailerLocations,
    visibleSelect,
    collectionGroup,
  ])

  return (
    <Fragment>
      <InfoDialog
        open={notEnabledDialogOpen}
        onClose={() => setNotEnabledDialogOpen(false)}
        title={t("tour_generation.feature_not_enabled")}
        content={
          <>
            <Typography>{t("tour_generation.feature_not_enabled_info")}</Typography>
            <a href="mailto:Support@sloc.com">{t("tour_generation.contact_support")}</a>
          </>
        }
      ></InfoDialog>

      <InfoDialog
        onClose={() => setInfoDialogOpen(false)}
        open={infoDialogOpen}
        content={<Typography>{t("tour_generation.materials_invalid")}</Typography>}
      />
      <ConfirmDialog
        confirmText={t("tour_generation.data.continue_tour_generation")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false)
          onClickGenerateTours(true)
        }}
        open={confirmOpen}
        heading={t("tour_generation.data.attention")}
        text={t("tour_generation.data.tour_generation_in_past")}
      />
      <ConfirmTourGenerationDialog
        open={confirmTourGenerationDialogOpen}
        onClose={() => setConfirmTourGenerationDialogOpen(false)}
        onConfirm={(scheduled?:boolean) => onConfirmGenerateTours(scheduled)}
        date={firstDrivingDay?.toDate() ?? null}
        loading={loading}
        collectionPoints={data?.tourExportPreview.collectionPoints || []}
        materials={data?.tourExportPreview.materials || []}
        districtName={district?.name || "-"}
        isGenerateBtnDisabled={data?.tourExportPreview.noRecycles === true}
        thresholdMin={thresholdMin}
        algorithmType={district?.algorithm}
      />
      <TourGenerationDisposalMerchantAvailabiltyDialog
        open={merchantAvailabilityDialog}
        onClose={() => setMerchantAvailabilityDialog(false)}
      />
      <ConcurrentTourGenerationsDialog
        open={concurrentGenerationsOpen}
        onClose={() => setConcurrentGenerationsOpen(false)}
        onConfirm={handleConfirmConcurrentTourGenerationsDialog}
      />
      <Dialog
      open={generationFailedDialogOpen} onClose={() => setGenerationFailedDialogOpen(false)}>
        <DialogTitle color="error" sx={{}}>
          <ErrorOutline style={{ color: "red", marginRight: "8px", verticalAlign: "middle" }} />
          <span style={{ color: "red" }}>
          {t("tour_generation.data.error")}
          </span>
        </DialogTitle>
        <DialogContent color="error" sx={{ }}>
        {t("tour_generation.data.error_try_again")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGenerationFailedDialogOpen(false)} color="primary">Zurück</Button>
        </DialogActions>
      </Dialog>

      <Dialog
      open={generationSuccessfullDialogOpen} onClose={() => setGenerationSuccessfullDialogOpen(false)}>
        <DialogTitle color="success" sx={{}}>
          <CheckCircle style={{ color: "green", marginRight: "8px", verticalAlign: "middle" }} />
          <span style={{ color: "green" }}>Erfolg</span>
        </DialogTitle>
        <DialogContent color="error" sx={{ }}>
        {t("tour_generation.data.tour_generated_successfully")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setGenerationSuccessfullDialogOpen(false)
            setConfirmTourGenerationDialogOpen(false)
            }} color="primary">
          {t("tour_generation.data.to_tour_generation")}
            </Button>
          <Button onClick={() => {
            store.set(STORE_KEYS.DISTRICT, district?.id)
            navigate(PATH.TOUR_OVERVIEW.route, { state: { tourParamId } });
          }} color="primary">          
            {t("tour_generation.data.show_tour")}
          </Button>
        </DialogActions>
      </Dialog>

      
      
      <Backdrop open={(scheduled || district?.algorithm !== AlgorithmType.VROOM) && generateToursLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      

      
      <Dialog
        open={!scheduled && district?.algorithm === AlgorithmType.VROOM && generateToursLoading}>
        <DialogContent sx={{ }}>
          <Grid 
          container 
          direction="column" 
          spacing={2} 
          justifyContent="center" 
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
              {t("tour_generation.data.tour_is_being_generated")}
            </Typography>
          </Grid>
        </Grid>
        </DialogContent>
      </Dialog>     
      <Card sx={{ width: "100%", p: 1 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" spacing={2} alignItems="flex-start">
            <Grid item xs={5}>
              <TourGenerationForm />
            </Grid>
            <Grid item sx={{ alignSelf: "stretch" }}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs>
              <TourGenerationVehicleSelection />
            </Grid>
          </Grid>
          <Grid item sx={{ alignSelf: "flex-end" }}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={disableGenerateTours && hasPermissions}
              onClick={() => onClickGenerateTours(false)}
            >
              {t("tour_generation.data.generate_tours")}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
