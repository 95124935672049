import { LocationCity, Spoke } from "@mui/icons-material"
import { alpha, Box, Grid, Stack, Switch, Tooltip, useTheme } from "@mui/material"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { TourGenerationContext, TourGenerationSelectValue } from "../../../../context/tour-generation-context"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"

export const TourGenerationTownOrGroupSelect: React.FC = () => {
  const {
    district,
    towns,
    setTowns,
    collectionGroup,
    setCollectionGroup,
    setMaterials,
    visibleSelect,
    setVisibleSelect,
  } = useContext(TourGenerationContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const { hasGroupSelect, hasTownSelect, canSelectGroupOrTown, visibleSelectDefaultVal } = useMemo(() => {
    const hasTownSelect = district?.allow_tourgeneration_town_multiselect
    const hasGroupSelect = district?.allow_tourgeneration_group_select
    const canSelectGroupOrTown = hasTownSelect && hasGroupSelect

    const visibleSelectDefaultVal: TourGenerationSelectValue | undefined = hasTownSelect
      ? TourGenerationSelectValue.Town
      : hasGroupSelect
        ? TourGenerationSelectValue.Group
        : undefined

    setVisibleSelect(undefined)
    return { hasTownSelect, hasGroupSelect, canSelectGroupOrTown, visibleSelectDefaultVal }
  }, [district?.allow_tourgeneration_group_select, district?.allow_tourgeneration_town_multiselect, setVisibleSelect])

  useEffect(() => {
    if (visibleSelect) return
    setVisibleSelect(visibleSelectDefaultVal)
  }, [visibleSelectDefaultVal, visibleSelect, setVisibleSelect])

  const columnWidth = canSelectGroupOrTown ? 4 : 6

  const mappedTownOptions = useMemo(() => {
    return district?.towns.map((town) => new SelectOption(String(town.id), town.name)) || []
  }, [district])

  const mappedCollectionGroupOptions = useMemo(() => {
    return (district?.collection_groups ?? []).map((group) => new SelectPair(String(group.id), group.label)) || []
  }, [district])

  const setSelectedTowns = (selectedOptions: SelectOption[]) => {
    const selectedTowns = district?.towns.filter((town) => selectedOptions.map((x) => x.id).includes(town.id))
    setTowns(selectedTowns ?? [])
  }

  const setSelectedCollectionGroup = (id: string) => {
    const group = (district?.collection_groups ?? []).find((g) => g.id === id)
    setCollectionGroup(group || null)
  }

  const handleSwitchChanged = (checked: boolean) => {
    if (checked) {
      // group is visible, reset towns
      setTowns([])
    } else {
      // town is visible, reset group and prefill towns
      setCollectionGroup(null)
      setTowns(district && district.towns ? district.towns : [])
    }
    setMaterials([])
    setVisibleSelect(checked ? TourGenerationSelectValue.Group : TourGenerationSelectValue.Town)
  }

  const isVisible = (type: "town" | "group") => {
    if (type === "town" && hasTownSelect && !hasGroupSelect) {
      return true
    }

    if (type === "group" && hasGroupSelect && !hasTownSelect) {
      return true
    }

    if (visibleSelect === undefined) {
      return type === visibleSelectDefaultVal
    }

    return type === visibleSelect
  }

  return (
    <>
      {hasTownSelect && isVisible("town") && (
        <Grid item xs={6} md={columnWidth}>
          <CustomAutocomplete
            id="tour-generation-towns"
            title={t("vehicle_overview.data.towns")}
            currentlySelectedValues={towns.map((town) => new SelectOption(String(town.id), town.name))}
            availableSelectOptions={mappedTownOptions}
            setCurrentlySelectedValues={setSelectedTowns}
          />
        </Grid>
      )}
      {hasGroupSelect && isVisible("group") && (
        <Grid item xs={6} md={columnWidth}>
          <OutlinedSelect
            options={mappedCollectionGroupOptions}
            name={t("tour_generation.collection_group.select")}
            onValueChange={setSelectedCollectionGroup}
            value={collectionGroup ? collectionGroup.id : ""}
          />
        </Grid>
      )}
      {canSelectGroupOrTown && (
        <Grid item xs={6} md={2}>
          <Stack direction="row" justifyContent="center" spacing={0}>
            <Box display="flex" alignItems="center">
              <Tooltip title={t("vehicle_overview.data.towns")}>
                <LocationCity
                  color="primary"
                  onClick={() => handleSwitchChanged(false)}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                />
              </Tooltip>
            </Box>
            <Switch
              size="small"
              checked={visibleSelect === "group"}
              onChange={(e) => handleSwitchChanged(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase": {
                  color: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                  },
                },
                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            />
            <Box display="flex" alignItems="center">
              <Tooltip title={t("tour_generation.collection_group.tooltip")}>
                <Spoke
                  color="primary"
                  onClick={() => handleSwitchChanged(true)}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                />
              </Tooltip>
            </Box>
          </Stack>
        </Grid>
      )}
    </>
  )
}
