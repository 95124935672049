import { gql } from "@apollo/client"
import {
  getMinimalToursWithRegionId,
  getMinimalToursWithRegionIdVariables,
  getMinimalToursWithRegionId_getToursWithRegionId,
} from "./types/getMinimalToursWithRegionId"

export const GETMINIMAL_TOURS_WITH_ASSID_QUERY = gql`
  query getMinimalToursWithRegionId($id: Int!, $type: RegionType!, $from: DateTime, $to: DateTime, $parametersId: String) {
    getToursWithRegionId(id: $id, type: $type, from: $from, to: $to, parametersId: $parametersId) {
      id
      date
      vehicle {
        licence_plate
      }
      collection_points(regionId: $id, regionType: $type) {
        id
      }
      materials {
        id
        name
      }
      parameters {
        without_containers
        id
      }
      manuallyCreated
    }
  }
`

export type MinimalTour = getMinimalToursWithRegionId_getToursWithRegionId
export type MinimalToursResult = getMinimalToursWithRegionId
export type MinimalToursVariables = getMinimalToursWithRegionIdVariables
