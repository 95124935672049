import { gql } from "@apollo/client"
import {
  householdContainers,
  householdContainersVariables,
  householdContainers_householdContainers,
  householdContainers_householdContainers_entries,
  householdContainers_householdContainers_entries_household,
} from "./types/householdContainers"

export const HOUSEHOLD_CONTAINERS_QUERY = gql`
  query householdContainers(
    $regionId: ID!
    $regionType: RegionType!
    $page: Int!
    $pagesize: Int!
    $order: HouseholdOrderInput!
    $countyIds: [ID!]!
    $address: String
    $rfid: String
    $materialIds: [ID!]!
    $townIds: [ID!]!
    $showWarnings: Boolean!
  ) {
    householdContainers(
      regionId: $regionId
      regionType: $regionType
      page: $page
      pagesize: $pagesize
      order: $order
      countyIds: $countyIds
      address: $address
      rfid: $rfid
      materialIds: $materialIds
      townIds: $townIds
      showWarnings: $showWarnings
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        materialId
        typeId
        comment
        rfid
        household {
          street
          number
          postal
          place
        }
      }
    }
  }
`

export type HouseholdContainersResult = householdContainers
export type HouseholdContainerVariables = householdContainersVariables
export type PaginatedHouseholdContainers = householdContainers_householdContainers
export type HouseholdContainer = householdContainers_householdContainers_entries
export type HouseholdForHouseholdContainer = householdContainers_householdContainers_entries_household
