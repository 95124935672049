import React, { useState, useContext, createContext, useMemo, useEffect } from "react"
import {
  GETMINIMAL_TOURS_WITH_ASSID_QUERY,
  MinimalTour,
  MinimalToursResult,
  MinimalToursVariables,
} from "../api/graphql/queries/get-minimal-tours-with-region-id"
import { useRefetch } from "./refetch-context"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { useSelectedRegion } from "../hooks/use-selected-region"
import { useLocation } from "react-router-dom";

interface ISelectedTourContextType {
  selectedTour: MinimalTour | undefined
  setSelectedTour: (tour: MinimalTour | undefined) => void
  tours: MinimalTour[]
  toursLoading: boolean
  toursError: Error | undefined
  dateFrom: moment.Moment | null
  setDateFrom: (date: moment.Moment | null) => void
  dateTo: moment.Moment | null
  setDateTo: (date: moment.Moment | null) => void
  setTourParamId: (tourParamId: string | null) => void
  tourParamId: string | null
}

export const SelectedTourContext = createContext<ISelectedTourContextType>({} as ISelectedTourContextType)

export const SelectedTourProvider = (props: any) => {
  const value = useSelectedTourProvider()

  return <SelectedTourContext.Provider value={value}>{props.children}</SelectedTourContext.Provider>
}

export const useSelectedTour = () => {
  return useContext(SelectedTourContext)
}

const useSelectedTourProvider = (): ISelectedTourContextType => {
  const [selectedTour, setSelectedTour] = useState<MinimalTour | undefined>(undefined)
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment().utc().startOf("day"))
  const [dateTo, setDateTo] = useState<moment.Moment | null>(moment().utc().endOf("day"))
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const [tourParamId, setTourParamId] = useState<string|null>(useLocation().state?.tourParamId);

  const { variables, skip } = useSelectedRegion()

  const { loading, data, error } = useQuery<MinimalToursResult, MinimalToursVariables>(
    GETMINIMAL_TOURS_WITH_ASSID_QUERY,
    {
      fetchPolicy: needToRefetch ? "network-only" : "cache-and-network",
      onCompleted: () => setNeedToRefetch(false),
      variables: {
        id: Number(variables.id),
        type: variables.type,
        from: dateFrom ? moment(dateFrom) && moment(dateFrom).clone().add(moment(dateFrom).utcOffset(), "minutes").toDate() : null,
        to: dateTo ? moment(dateTo) && moment(dateTo).clone().add(moment(dateTo).utcOffset(), "minutes").toDate() : null,
        parametersId: tourParamId
      },
      skip,
    },
  )

  const tours = useMemo(
    () => data?.getToursWithRegionId.slice()
    .sort((a, b) => moment(a.date).diff(moment(b.date))) || [],
    [data],
  )

    useEffect(() => {
      if (tourParamId && tours.length > 0) {
        const matchingTour = tours.find((tour) => tour.parameters?.id === tourParamId);
        if (matchingTour) {
          setSelectedTour(matchingTour);                  
          setDateFrom(matchingTour.date)
          setDateTo(tours[tours.length - 1].date)
        }
      }
    }, [tourParamId, tours]);
    
  return {
    selectedTour,
    setSelectedTour,
    tours,
    toursLoading: loading,
    toursError: error,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    setTourParamId,
    tourParamId
  }
}
