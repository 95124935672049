import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Dialog, DialogTitle, DialogContent, Grid, Tooltip, DialogActions, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import moment, { Moment } from "moment"
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers"
import { TourGenerationContext, maxTerminationTimeMinimum } from "../../../../context/tour-generation-context"
import { Info } from "@mui/icons-material"
import { AlgorithmType } from "../../../../api/graphql/graphql-global-types"

interface IScheduleTourGenerationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  algorithmType: AlgorithmType
}

export const ScheduleTourGenerationDialog: FunctionComponent<IScheduleTourGenerationDialogProps> = (props) => {
  const { t } = useTranslation()
  const { onClose, onConfirm, open, algorithmType } = props

  const { maxTerminationTime, setMaxTerminationTime, scheduleDate, setScheduleDate, maxTerminationTimeValid } =
    useContext(TourGenerationContext)

  const [scheduleDateValid, setScheduleDateValid] = useState<boolean>(false)

  useEffect(() => {
    if (scheduleDate === null && open) {
      setScheduleDate(moment().add(30, "minutes"))
    }
  }, [scheduleDate, setScheduleDate, open])

  useEffect(() => {
    setScheduleDateValid(scheduleDate !== null && scheduleDate.isValid() && scheduleDate.isAfter(moment(), "minutes"))
  }, [scheduleDate])

  const onCancel = () => {
    setScheduleDate(null)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="lg">
      <DialogTitle>{t("tour_generation.schedule_dialog.title")}</DialogTitle>
      <DialogContent>
        <Grid container direction="row" sx={{ mb: 1, p: 1 }} spacing={2}>
          {algorithmType === AlgorithmType.VROOM || <Grid item container xs={12} direction="row" alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes"]}
                format="HH:mm"
                label={t("tour_generation.data.max_termination_time")}
                value={maxTerminationTime}
                onChange={(date: moment.Moment | null) => setMaxTerminationTime(date as Moment)}
                minTime={maxTerminationTimeMinimum}
                maxTime={moment("11:59", "HH:mm")}
                slotProps={{
                  desktopPaper: {
                    sx: {
                      width: 150,
                    },
                  },
                  layout: {
                    sx: {
                      ".MuiMultiSectionDigitalClock-root": {
                        ".MuiList-root": {
                          width: "100%",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip title={t("tour_generation.schedule_dialog.max_termination_time_info") as string}>
                <Info color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          }
          <Grid item container xs={12} direction="row" alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <DateTimePicker
                disablePast
                sx={{ width: "100%" }}
                ampm={false}
                format={t("date_time_input_format")}
                label={t("tour_generation.data.schedule_date")}
                value={scheduleDate}
                shouldDisableTime={(value, view) => {
                  if (view === "hours" && value.hour() > 11) return true;
                  if (view === "minutes" && value.hour() === 11 && value.minute() > 59) return true;
                  return false;
                }}
                onChange={(date: moment.Moment | null) => setScheduleDate(date as Moment)}
              />
            </Grid>
            <Grid item>
              <Tooltip title={t("tour_generation.schedule_dialog.schedule_date_info") as string}>
                <Info color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" spacing={2} justifyContent="space-between">
          <Grid item>
            <Button onClick={onCancel} autoFocus variant="contained" color="inherit">
              {t("tour_generation.confirm_dialog.cancel")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="primary"
              disabled={!scheduleDateValid || !maxTerminationTimeValid}
            >
              {t("tour_generation.confirm_dialog.generate")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
