import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import moment, { Moment } from "moment"
import { Box, Button, Grid, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { executeCallbackOnEnter } from "../../../../utils/form"
import { HouseholdTourItem } from "./household-discharges-tour-item"
import { HouseholdDischargesContext } from "../context/household-discharges-context"
import { HouseholdDischargesOfTourPaginated } from "../../../../api/graphql/queries/get-household-discharges-of-tour-paginated"

export interface IHouseholdTour {
  id: number
  tourDate: string
  vehicleIdIdentSystem: string
  vehicleTypePlate: string
  material: { id: number; name: string }
  districtId: number
  discharges: HouseholdDischargesOfTourPaginated[]
}

export const HouseholdTourSelector: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { householdTours, setDateFrom, setDateTo, setSelectedTour } = useContext(HouseholdDischargesContext)

  const [dateFromTemp, setDateFromTemp] = useState<Moment | null>(moment().subtract(1, "day").utc().startOf("day"))
  const [dateToTemp, setDateToTemp] = useState<Moment | null>(null)

  const onFilterApplyClicked = () => {
    setSelectedTour(undefined)
    if ((!dateFromTemp || dateFromTemp.isValid()) && (!dateToTemp || dateToTemp.isValid())) {
      setDateFrom(dateFromTemp)
      setDateTo(dateToTemp)
    }
  }

  const onFilterRemoveClicked = () => {
    setDateFrom(moment().subtract(1, "day").utc().startOf("day"))
    setDateTo(null)
    setDateFromTemp(moment().subtract(1, "day").utc().startOf("day"))
    setDateToTemp(null)
    setSelectedTour(undefined)
  }

  return (
    <Grid container direction="column" sx={{ width: 270, pt: 1 }} spacing={1}>
      <Grid item>
        <DatePicker
          label={t("date_picker.from")}
          format={t("date_format")}
          value={dateFromTemp}
          onChange={(date: moment.Moment | null) => date && setDateFromTemp(date)}
          slotProps={{
            textField: {
              onKeyDown: executeCallbackOnEnter(onFilterApplyClicked),
              size: "small",
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={t("date_picker.to")}
          format={t("date_format")}
          minDate={dateFromTemp ? dateFromTemp.clone() : undefined}
          value={dateToTemp}
          onChange={(date: moment.Moment | null) =>
            date && date.isValid() && setDateToTemp(date.utc().startOf("day") as moment.Moment)
          }
          slotProps={{
            textField: {
              onKeyDown: executeCallbackOnEnter(onFilterApplyClicked),
              size: "small",
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item>
        <Button fullWidth variant="contained" type="button" color="primary" onClick={onFilterApplyClicked}>
          {t("household_discharges.filter.apply")}
        </Button>
      </Grid>
      <Grid item>
        <Button color="inherit" fullWidth variant="contained" type="button" onClick={onFilterRemoveClicked}>
          {t("household_discharges.filter.remove")}
        </Button>
      </Grid>

      <Grid item>
        {householdTours && householdTours.length > 0 && (
          <Box sx={{ p: 0, flex: "1 1 auto", overflowY: "auto", maxWidth: 300 - parseFloat(theme.spacing(1)) }}>
            {householdTours.map((tour, index) => (
              <HouseholdTourItem key={index} num={index + 1} item={tour} />
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
