const userAgent = window.navigator.userAgent

export const isIE = userAgent.indexOf("MSIE") > 0 || userAgent.indexOf("Trident/") > 0

export enum ContentType {
  EXCEL = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const downloadBase64 = (data: { base64: string; filename: string }, contentType: ContentType) => {
  if (data?.base64 && data?.filename) {
    const { base64, filename } = data

    // IE
    if (isIE && (window.navigator as any).msSaveOrOpenBlob) {
      const byteCharacters = atob(base64)

      let byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: contentType })
      ;(window.navigator as any).msSaveOrOpenBlob(blob, filename)
    } else {
      const element = document.createElement("a")
      element.href = contentType + ";base64," + base64

      element.download = filename
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    return true
  }
  return false
}

export const downloadURI = (uri: string) => {
  const link = document.createElement("a")
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadXmlString = (xml: string, filename: string) => {
  downloadFile(xml, "text/csv", filename, `xml`)
}

export const downloadCsvString = (csv: string, filename: string) => {
  downloadFile(csv, "application/xml", filename, `csv`)
}

const downloadFile = (data: string, type: string, filename: string, fileType: string) => {
  const blob = new Blob([data], { type: type })
  const url = window.URL.createObjectURL(blob)
  const element = document.createElement("a")
  element.href = url
  element.download = `${filename}.${fileType}`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  window.URL.revokeObjectURL(url)
}

