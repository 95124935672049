import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { getDisposalMerchants_getDisposalMerchants } from "../../../../api/graphql/queries/types/getDisposalMerchants";
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id";
import { useTranslation } from "react-i18next";

interface DisposalMerchantsStopTableProps {
  data: getDisposalMerchants_getDisposalMerchants[];
  districtId: number;
  selectedStopPoint?: StopInfo;
  setSelectedStopPoint: (stop?: StopInfo) => void;
  mapDisposalMerchantToStopInfo: (dm: getDisposalMerchants_getDisposalMerchants) => StopInfo;
}

const DisposalMerchantsStopTable: React.FC<DisposalMerchantsStopTableProps> = ({
  data,
  districtId,
  selectedStopPoint,
  setSelectedStopPoint,
  mapDisposalMerchantToStopInfo,
}) => {
  const { t } = useTranslation()
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell><b>{t("collection_points.table.id").toUpperCase()}</b></TableCell>
          <TableCell><b>{t("collection_points.table.name")}</b></TableCell>
          <TableCell><b>{t("collection_points.table.district")}</b></TableCell>
          <TableCell><b> {t("collection_points.table.address")}</b></TableCell>
          <TableCell><b>{t("collection_points.table.materials")}</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          .filter((m) => m.districts?.some((d) => d.id === districtId.toString()))
          .map((disposalMerchant) => (
            <TableRow
              key={disposalMerchant.id}
              hover
              onClick={() => {
                if (disposalMerchant.id === selectedStopPoint?.id) {
                  setSelectedStopPoint(undefined);
                } else {
                  const dm = data
                    .filter((m) => m.districts?.some((d) => d.id === districtId.toString()))
                    .find((dm) => dm?.id === disposalMerchant.id);
                  if (dm) {
                    setSelectedStopPoint(mapDisposalMerchantToStopInfo(dm));
                  }
                }
              }}
              sx={{
                backgroundColor: selectedStopPoint?.id === disposalMerchant.id ? "rgba(184, 126, 18, 0.08)" : "inherit",
                "&:hover": { backgroundColor: "rgba(0, 123, 255, 0.1)" },
              }}
            >
              <TableCell>{disposalMerchant.id}</TableCell>
              <TableCell>{disposalMerchant.name}</TableCell>
              <TableCell>{disposalMerchant?.districts?.map((d) => d.name).join(", ")}</TableCell>
              <TableCell>{`${disposalMerchant.postal || ""} ${disposalMerchant.town || ""} ${disposalMerchant.street || ""}`}</TableCell>
              <TableCell>{disposalMerchant.materials?.map((m) => m.name).join(", ") || ""}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default DisposalMerchantsStopTable;
