import React, { FC, PropsWithChildren, useState } from "react"
import { useQuery } from "@apollo/client"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"
import {
  GET_HOUSEHOLD_TOURS_QUERY,
  HouseholdTour,
  HouseholdToursResult,
  HouseholdToursVariables,
} from "../../../../api/graphql/queries/get-household-tours"
import {
  GET_HOUSEHOLD_DISCHARGES_OF_TOUR_QUERY,
  HouseholdDischargesOfTour,
  HouseholdDischargesOfTourResult,
  HouseholdDischargesOfTourVariables,
} from "../../../../api/graphql/queries/get-household-discharges-of-tour"
import {
  GET_HOUSEHOLD_DISCHARGES_OF_TOUR_PAGINATED_QUERY,
  HouseholdDischargesOfTourPaginated,
  HouseholdDischargesOfTourPaginatedResult,
  HouseholdDischargesOfTourPaginatedVariables,
} from "../../../../api/graphql/queries/get-household-discharges-of-tour-paginated"
import moment, { Moment } from "moment"
import {
  GET_HOUSEHOLD_MAIN_TOWN__OF_TOUR_QUERY,
  HouseholdMainTownOfTourResult,
  HouseholdMainTownOfTourVariables,
} from "../../../../api/graphql/queries/get-household-main-town-of-tour"
import { HouseholdExportFormat } from "../../../../api/graphql/graphql-global-types"

interface TableProps {
  page: number
  pageSize: number
}

interface IHouseholdDischargesContext {
  dateFrom: Moment | null
  setDateFrom: (date: Moment | null) => void
  dateTo: Moment | null
  setDateTo: (date: Moment | null) => void
  householdTours: HouseholdTour[] | undefined
  export_format: HouseholdExportFormat
  selectedTour: HouseholdTour | undefined
  setSelectedTour: (tour: HouseholdTour | undefined) => void
  householdDischarges: HouseholdDischargesOfTour[] | undefined | null
  householdDischargesPaginated: HouseholdDischargesOfTourPaginated[] | undefined | null
  tableProps: TableProps
  setTableProps: (props: TableProps) => void
  tableWarningFilter: boolean
  setTableWarningFilter: (warningFilter: boolean) => void
  refetchHouseholdDischargeData: () => Promise<void>
}

export const HouseholdDischargesContext = React.createContext<IHouseholdDischargesContext>({
  dateFrom: moment().subtract(1, "day").utc().startOf("day"),
  setDateFrom: () => {},
  dateTo: null,
  setDateTo: () => {},
  householdTours: undefined,
  export_format: HouseholdExportFormat.XML,
  selectedTour: undefined,
  setSelectedTour: () => {},
  householdDischarges: undefined,
  householdDischargesPaginated: undefined,
  tableProps: { page: 0, pageSize: 5 },
  setTableProps: () => {},
  tableWarningFilter: false,
  setTableWarningFilter: () => {},
  refetchHouseholdDischargeData: async () => {},
})

export const HouseholdDischargesContextProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const { skip, variables } = useSelectedRegion()

  const [dateFrom, setDateFrom] = useState<Moment | null>(moment().subtract(1, "day").utc().startOf("day"))
  const [dateTo, setDateTo] = useState<Moment | null>(null)
  const [tableProps, setTableProps] = useState<TableProps>({ page: 0, pageSize: 5 })
  const [tableWarningFilter, setTableWarningFilter] = useState<boolean>(false)

  const [selectedTour, setSelectedTour] = useState<HouseholdTour | undefined>(undefined)

  const { data: HouseholdMainTownOfTour } = useQuery<HouseholdMainTownOfTourResult, HouseholdMainTownOfTourVariables>(
    GET_HOUSEHOLD_MAIN_TOWN__OF_TOUR_QUERY,
    {
      variables: { tourId: selectedTour?.id ? Number(selectedTour.id) : -1 },
      skip: !selectedTour,
    }
  )

  const { data: householdToursData } = useQuery<HouseholdToursResult, HouseholdToursVariables>(
    GET_HOUSEHOLD_TOURS_QUERY,
    {
      variables: { districtId: Number(variables.id), to: dateTo, from: dateFrom },
      skip,
    },
  )

  const { data: householdDischargesData, refetch: refetchHouseholdDischargesData } = useQuery<
    HouseholdDischargesOfTourResult,
    HouseholdDischargesOfTourVariables
  >(GET_HOUSEHOLD_DISCHARGES_OF_TOUR_QUERY, {
    variables: { getHouseholdDischargesOfTourId: selectedTour?.id ? Number(selectedTour.id) : -1},
    skip: !selectedTour,
  })

  const { data: householdDischargesPaginatedData, refetch: refetchHouseholdDischargesPaginatedData } = useQuery<
    HouseholdDischargesOfTourPaginatedResult,
    HouseholdDischargesOfTourPaginatedVariables
  >(GET_HOUSEHOLD_DISCHARGES_OF_TOUR_PAGINATED_QUERY, {
    variables: {
      tourId: selectedTour?.id ? Number(selectedTour.id) : -1,
      page: tableProps.page,
      pageSize: tableProps.pageSize,
      warningFilter: tableWarningFilter,
    },
    skip: !selectedTour,
  })

  const refetchHouseholdDischargeData = async () => {
    await refetchHouseholdDischargesData()
    await refetchHouseholdDischargesPaginatedData()
  }

  return (
    <HouseholdDischargesContext.Provider
      value={{
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        export_format: HouseholdMainTownOfTour?.getHouseholdMainTownOfTour.export_format === 'CSV' ? HouseholdExportFormat.CSV : HouseholdExportFormat.XML,
        householdTours: householdToursData?.getHouseholdTours,
        selectedTour,
        setSelectedTour,
        householdDischarges: householdDischargesData?.getHouseholdDischargesOfTour,
        householdDischargesPaginated: householdDischargesPaginatedData?.getHouseholdDischargesOfTourPaginated,
        tableProps,
        setTableProps,
        tableWarningFilter,
        setTableWarningFilter,
        refetchHouseholdDischargeData
      }}
    >
      {props.children}
    </HouseholdDischargesContext.Provider>
  )
}
