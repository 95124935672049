import React, { FunctionComponent } from "react"
import { Grid } from "@mui/material"
import { Text } from "../../wrapper/text"

export interface ICustomMarkerTooltipPropertyProps {
  name: string
  content?: string | null
}

export const CustomMarkerTooltipProperty: FunctionComponent<ICustomMarkerTooltipPropertyProps> = (props) => {
  const { name, content } = props
  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item container justifyContent="space-between" sx={{ minWidth: 130, maxWidth: 170, pr: 1 }}>
        <Text bold>{name}</Text>
      </Grid>
      <Grid item>
        <Text bold={false}>{content}</Text>
      </Grid>
    </Grid>
  )
}
