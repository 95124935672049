import { useQuery } from "@apollo/client"
import { Grid, Skeleton, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import lodash from "lodash"
import moment from "moment"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { AlgorithmType } from "../../../../api/graphql/graphql-global-types"
import {
  GetTourParametersResult,
  GetTourParametersVariables,
  GET_TOUR_PARAMETERS,
} from "../../../../api/graphql/queries/get-tour-parameters"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { WeekPicker } from "../../../partials/date-picker/week-picker"
import { TourGenerationIcon } from "../../../partials/icons/tour-generation-icon"
import { DrivingDayChips } from "./driving-day-chips"

interface ITourParametersContentProps {
  tourParametersId: string | undefined
}

export const TourParametersContent: FC<ITourParametersContentProps> = (props) => {
  const { tourParametersId } = props
  const { t } = useTranslation()

  const { data, loading } = useQuery<GetTourParametersResult, GetTourParametersVariables>(GET_TOUR_PARAMETERS, {
    variables: {
      tourParametersId: tourParametersId ?? "", // handled in skip condition
    },
    skip: !tourParametersId,
  })

  if (loading || !data?.getTourParameters) {
    return (
      <>
        {lodash.range(3).map((x) => (
          <Skeleton key={x} width={800} height={50} />
        ))}
      </>
    )
  }

  const {
    district,
    calculation_start,
    calculation_end,
    start_date,
    threshold,
    threshold_min,
    version,
    without_containers,
    disposal_trailer_locations,
    towns,
    driving_days,
    reference_date,
  } = data.getTourParameters

  const showTowns = towns != null && towns.length > 0 && district.towns.length !== towns.length
  const isTrailerTour = version === AlgorithmType.TO2TRAILER

  return (
    <Grid container spacing={2}>
      {/* NEWLINE */}
      <Grid container item xs={12} justifyContent={"center"}>
        <TourGenerationIcon fontSize="large" withoutContainers={without_containers} />
      </Grid>

      {/* NEWLINE */}
      <DrivingDayChips startDate={start_date} drivingDays={driving_days ?? []} />

      {/* NEWLINE */}
      <Grid item xs={showTowns ? 6 : 12}>
        <TextField
          size="small"
          disabled
          fullWidth
          label={t("tour_generation.tour_parameters_dialog.data.district")}
          value={district.name}
          variant="outlined"
        />
      </Grid>

      {showTowns && (
        <Grid item xs={6}>
          <CustomAutocomplete
            id="tour-generation-towns"
            title={t("vehicle_overview.data.towns")}
            currentlySelectedValues={towns?.map((town) => new SelectOption(town.id, town.name)) ?? []}
            availableSelectOptions={district.towns.map((town) => new SelectOption(town.id, town.name))}
            setCurrentlySelectedValues={() => {}}
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <TextField
          size="small"
          disabled
          fullWidth
          label={t("tour_generation.tour_parameters_dialog.data.threshold_min")}
          value={threshold_min ?? "-"}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          disabled
          fullWidth
          label={t("tour_generation.tour_parameters_dialog.data.threshold")}
          value={threshold ?? "-"}
          variant="outlined"
        />
      </Grid>

      {/* */}
      <Grid item xs={6}>
        <WeekPicker
          value={moment(start_date)}
          onWeekChanged={() => {}}
          minDate={moment(start_date).startOf("week")}
          maxDate={moment(start_date).endOf("week")}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <DatePicker
          value={moment(reference_date)}
          onChange={() => {}}
          disabled
          label={t("tour_generation.data.reference_date")}
          format={t("date_format")}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
          }}
        />
      </Grid>

      {/* NEWLINE */}
      <Grid item xs={6}>
        <TextField
          size="small"
          disabled
          fullWidth
          label={t("tour_generation.tour_parameters_dialog.data.calculation_start")}
          value={moment(calculation_start).format(t("date_time_format"))}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          disabled
          fullWidth
          label={t("tour_generation.tour_parameters_dialog.data.calculation_end")}
          value={moment(calculation_end).format(t("date_time_format"))}
          variant="outlined"
        />
      </Grid>

      {/* NEWLINE */}
      {isTrailerTour && (
        <Grid item xs={12}>
          <TextField
            size="small"
            disabled
            fullWidth
            label={t("tour_generation.tour_parameters_dialog.data.calculation_start")}
            value={disposal_trailer_locations?.map((v) => v.name) ?? "-"}
            variant="outlined"
          />
        </Grid>
      )}
    </Grid>
  )
}
