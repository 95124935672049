import React, { FunctionComponent, useMemo, useCallback } from "react"
import { Grid, CircularProgress, IconButton } from "@mui/material"
import { BASE_PATH, PATH } from "../../../../../router/router"
import {
  CollectionPointResult,
  GETCOLLECTIONPOINTWITHID_QUERY,
} from "../../../../../api/graphql/queries/get-collection-points-with-id"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"

import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useTranslation } from "react-i18next"
import { useTourOverview } from "../../context/tour-overview-context"
import lodash from "lodash"
import {
  TourOverviewMapMarkerTooltipProperty,
  ITourOverviewMapMarkerTooltipPropertyProps,
} from "./tour-overview-map-marker-tooltip-property"

interface TourOverviewMapMarkerTooltipProps {
  stopId: number
}

export const TourOverviewMapMarkerTooltip: FunctionComponent<TourOverviewMapMarkerTooltipProps> = (props) => {
  const { stopId } = props
  const { t } = useTranslation()
  const { stopInfos, tourStatsLoading } = useTourOverview()

  const formatDate = useCallback(
    (date: Date | string | null) => {
      return date ? moment(date).format(t("date_format")) : "-"
    },
    [t],
  )
  const formatTime = useCallback((date: Date | string | null) => {
    return date ? moment(date).format("HH:mm") : "-"
  }, [])

  const stopInfo = useMemo(() => stopInfos.find((entry) => entry.id === stopId), [stopInfos, stopId])

  const { loading: loadingCollectionPoint, data: collectionPointData } = useQuery<CollectionPointResult>(
    GETCOLLECTIONPOINTWITHID_QUERY,
    {
      variables: {
        id: Number(stopInfo?.id),
      },
      skip: !stopInfo?.id,
    },
  )

  const collectionPoint = useMemo(() => collectionPointData?.getCollectionPointWithID, [collectionPointData])

  const fillLevelMaterials = useMemo(() => {
    if (!stopInfo) return []
    return stopInfo.stop_filllevels.map((fl) => {
      return {
        name: fl.material.name,
        fillLevel: fl.filllevel,
        containerCount: lodash.filter(
          collectionPoint?.containers,
          (container) => `${container.material_id}` === fl.material.id,
        ).length,
      }
    })
  }, [stopInfo, collectionPoint])

  const vehicleUtilization = useMemo(() => {
    if (!stopInfo) return []
    const utilizations = stopInfo?.vehicle_utilizations?.map((vu) => {
      return {
        material: vu.material,
        capacity: vu.level,
      }
    })

    return utilizations
      ?.filter((u) => u.capacity > 0)
      .map((utilization) => `${utilization.material.name}: ${utilization.capacity}%`)
  }, [stopInfo])

  const propertiesToShow = useMemo(() => {
    if (!collectionPoint || !stopInfo) return []
    const properties: ITourOverviewMapMarkerTooltipPropertyProps[] = [
      {
        name: t("tour_overview.tooltip.location_number"),
        content: collectionPoint.location_number,
      },
      {
        name: t("tour_overview.tooltip.description"),
        content: collectionPoint.description,
      },
      {
        name: t("tour_overview.tooltip.last_emptying"),
        content: formatDate(collectionPoint.last_emptying),
      },
      {
        name: t("tour_overview.tooltip.fill_levels"),
        content:
          fillLevelMaterials.map((fl) => `${fl.name} (${fl.containerCount}): ${fl.fillLevel}%`).join("\n") || "-",
      },
      {
        name: t("tour_overview.tooltip.leave_at"),
        content: formatTime(stopInfo.leave_at),
        sx: {
          root: {
            pt: 2,
          },
        },
      },
      {
        name: t("tour_overview.tooltip.completed_at"),
        content: formatTime(stopInfo.updated_at),
      },
      {
        name: t("tour_overview.tooltip.vehicle_utilization"),
        content: vehicleUtilization?.join("\n") || "-",
        sx: {
          root: {
            pt: 2,
          },
        },
      },
    ]
    return properties
  }, [t, collectionPoint, stopInfo, fillLevelMaterials, formatDate, formatTime, vehicleUtilization])

  return (
    <Grid
      container
      sx={{ minHeight: 160, width: 360 }}
      alignContent={loadingCollectionPoint ? "center" : "flex-start"}
      justifyContent={loadingCollectionPoint ? "center" : "flex-start"}
    >
      {(loadingCollectionPoint || tourStatsLoading) && <CircularProgress />}
      {!(loadingCollectionPoint || tourStatsLoading) && (
        <Grid container sx={{ height: "100%" }} direction="row">
          <Grid container item xs spacing={0}>
            {propertiesToShow.map((property, index) => {
              return <TourOverviewMapMarkerTooltipProperty key={index} {...property} />
            })}
          </Grid>
          <Grid item>
            <Grid container item alignContent="center" justifyContent="center" sx={{ height: "100%" }}>
              <Link to={`${BASE_PATH}/${PATH.COLLECTIONPOINT.id}/${collectionPoint?.id}`}>
                <IconButton>
                  <OpenInNewIcon />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
