import React, { FunctionComponent, Fragment, useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, Typography, Skeleton, Box, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CollectionPointsFilter } from "../../collection-points/partials/collection-points-filter"
import { CompactCollectionPointsTable } from "../../../partials/layout/table/compact-collection-points-table"
import { useQuery } from "@apollo/client"
import { ExportReason, RegionType, StopStatus, STOP_TYPE } from "../../../../api/graphql/graphql-global-types"
import {
  GetCollectionPointsWithRegionIDResult,
  GetCollectionPointsWithRegionIDVariables,
  GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY,
  CollectionPoint,
} from "../../../../api/graphql/queries/get-collection-points-with-region-id"
import {
  CollectionPointFilllevelsForDateResult,
  CollectionPointFilllevelsForDateVariables,
  COLLECTION_POINTS_FOR_DATE_QUERY,
} from "../../../../api/graphql/queries/collection-point-filllevels-for-date"
import { useCollectionPointsFilter } from "../../../../context/CollectionPointsFilterContext"
import { MaterialsResult, GETMATERIALS_QUERY } from "../../../../api/graphql/queries/get-materials"
import lodash from "lodash"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { DisposalMerchantsResult, GETDISPOSALMERCHANTS_QUERY } from "../../../../api/graphql/queries/get-disposal-merchants"
import { useRefetch } from "../../../../context/refetch-context"
import { getDisposalMerchants_getDisposalMerchants } from "../../../../api/graphql/queries/types/getDisposalMerchants"
import DisposalMerchantsStopTable from "../../../partials/layout/table/disposal-merchants-stop-table"

interface IAddCollectionPointDialogProps {
  newSequenceNumber: number
  open: boolean
  onClose: () => void
  onAddCollectionPoint: (stopInfo: StopInfo) => void
  districtId: number
  date: Date
}

export const AddCollectionPointDialog: FunctionComponent<IAddCollectionPointDialogProps> = (props) => {
  const { t } = useTranslation()
  const { onClose, onAddCollectionPoint, open, districtId, newSequenceNumber, date } = props
  const { filter, tableProps, setTableProps } = useCollectionPointsFilter()
  const [selectedStopPoint, setSelectedStopPoint] = useState<StopInfo | undefined>()
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(0);

  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { needToRefetch, setNeedToRefetch } = useRefetch()

  const {data} = useQuery<DisposalMerchantsResult>(GETDISPOSALMERCHANTS_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  })
  
  const { data: collectionPointsData, loading: collectionPointsLoading } = useQuery<
    GetCollectionPointsWithRegionIDResult,
    GetCollectionPointsWithRegionIDVariables
  >(GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY, {
    variables: {
      id: districtId,
      type: RegionType.DISTRICT,
      page: tableProps.page,
      pagesize: tableProps.pagesize,
      order: {
        orderBy: tableProps.order.orderBy,
        sortingType: tableProps.order.sortingType,
        materialIdToSortBy: tableProps.order.materialIdToSortBy,
      },
      filter,
    },
    skip: lodash.isNil(districtId) || isNaN(districtId),
  })

  const {
    data: filllevelData,
    loading: filllevelLoading,
    error: filllevelError,
  } = useQuery<CollectionPointFilllevelsForDateResult, CollectionPointFilllevelsForDateVariables>(
    COLLECTION_POINTS_FOR_DATE_QUERY,
    {
      variables: {
        id: String(selectedStopPoint?.id),
        date,
      },
      skip: lodash.isNil(selectedStopPoint) || selectedStopPoint.type !== STOP_TYPE.COLLECTION_POINT,
    },
  )

  const onFilterUpdated = () => {
    setSelectedStopPoint(undefined)
    setTableProps({ ...tableProps, page: 0 })
  }

  const mapDisposalMerchantToStopInfo = (disposalMerchant: getDisposalMerchants_getDisposalMerchants): StopInfo => {
    return {
        stop_id: -1,
        sequence_number: newSequenceNumber,
        reason: ExportReason.MANUALLY_INSERTED,
        id: Number(disposalMerchant?.id),
        lat: disposalMerchant?.latitude,
        lng: disposalMerchant?.longitude,
        name: disposalMerchant?.name || "",
        place: disposalMerchant?.districts ? disposalMerchant?.districts[0].name : "",
        postal: disposalMerchant?.postal ? String(disposalMerchant?.postal) : "",
        street: disposalMerchant?.street,
        town: disposalMerchant?.town,
        fixed_unload_interval: null, 
        updated_at: null,
        type: STOP_TYPE.DISPOSAL_MERCHANT,
        weighings: [],
        collection_point_images: [],
        leave_at: null,
        vehicle_utilizations: [],
        stop_filllevels:  [],
        status: StopStatus.NONE,
        __typename: "StopInfo",
      }
  }

  const mapCollectionPointConToStopInfo = (collectionPoint: CollectionPoint): StopInfo => {
    return {
      stop_id: -1,
      sequence_number: newSequenceNumber,
      reason: ExportReason.MANUALLY_INSERTED,
      id: Number(collectionPoint.id),
      lat: collectionPoint.latitude,
      lng: collectionPoint.longitude,
      name: collectionPoint.description || "",
      place: collectionPoint.place,
      postal: collectionPoint.postal ? String(collectionPoint.postal) : "",
      street: collectionPoint.street,
      town: collectionPoint.town?.name,
      fixed_unload_interval: collectionPoint.fixed_unload_interval || null,
      updated_at: null,
      type: STOP_TYPE.COLLECTION_POINT,
      weighings: [],
      collection_point_images: [],
      leave_at: null,
      vehicle_utilizations: [],
      stop_filllevels: filllevelData ? filllevelData.collectionPointFilllevelsForDate.map((filllevel) => ({
        filllevel: Math.round(Number(filllevel.fill_level.value)),
        amount: null,
        material: {
          id: String(filllevel.material_id),
          name: filllevel.material_name,
          __typename: "Material",
        },
        __typename: "StopFilllevel",
      })) : [],
      status: StopStatus.NONE,
      __typename: "StopInfo",
    }
  }

  const onStopConfirmed = () => {
    setConfirmOpen(false)
    if (selectedStopPoint) {
      if(filllevelData && selectedStopPoint.type === STOP_TYPE.COLLECTION_POINT){
        selectedStopPoint.stop_filllevels = filllevelData!.collectionPointFilllevelsForDate.map((filllevel) => ({
          filllevel: Math.round(Number(filllevel.fill_level.value)),
          amount: null,
          material: {
            id: String(filllevel.material_id),
            name: filllevel.material_name,
            __typename: "Material",
          },
          __typename: "StopFilllevel",
        }));
      }

      if(filllevelData || selectedStopPoint.type !== STOP_TYPE.COLLECTION_POINT){
        onAddCollectionPoint(selectedStopPoint)
      }
    }
  }

  return (
    <Fragment>
      <ConfirmDialog
        confirmText={t("add_collection_point_dialog.add")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => onStopConfirmed()}
        open={confirmOpen}
        heading={t("add_collection_point_dialog.confirm_title", {
          id: selectedStopPoint?.id,
        })}
        loading={filllevelLoading}
        error={!!filllevelError}
      >
        {!lodash.isNil(selectedStopPoint) && (
          <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={2}>
              <Grid item container direction="column" xs>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.sequence_number")}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.description")}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.address")}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs>
                <Grid item>
                  <Typography>{newSequenceNumber + 1}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{selectedStopPoint?.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                  {`${selectedStopPoint?.postal || ""} ${selectedStopPoint?.town || ""} ${selectedStopPoint?.street || ""}`}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="column">
              <Grid item>
                <Typography sx={{ fontWeight: "bold" }}>{t("add_collection_point_dialog.materials")}</Typography>
              </Grid>
              {!filllevelLoading && filllevelData ? (
                <Grid item>
                  {!!filllevelError ? (
                    <Typography color="textSecondary">{t("add_collection_point_dialog.materials_error")}</Typography>
                  ) : (
                    filllevelData.collectionPointFilllevelsForDate.map((filllevel) => (
                      <Grid item key={filllevel.material_id}>
                        {filllevel.material_name}: {Math.round(Number(filllevel.fill_level.value))}% (
                        {Math.round(filllevel.weight * 100) / 100}kg)
                      </Grid>
                    ))
                  )}
                </Grid>
              ) : (
                <Skeleton animation="wave" />
              )}
            </Grid>
          </Grid>
        )}
      </ConfirmDialog>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true} PaperProps={{ sx: { minHeight: "92%" }}}>
        <DialogTitle>{t("add_collection_point_dialog.title")}</DialogTitle>
        <DialogContent>
          <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)} centered>
            <Tab label={t("add_collection_point_dialog.collection_point")} />
            <Tab label={t("add_collection_point_dialog.disposal_merchant")} />
          </Tabs>
          <Box mt={2} maxWidth="lg" maxHeight="lg">
            {activeTab === 0 ? (
              <Grid container direction="column">
                <Grid item>
                  <CollectionPointsFilter
                    districtId={districtId}
                    variant="tour_administration"
                    onFilterUpdated={onFilterUpdated}
                  />
                </Grid>
                <Grid item>
                  <CompactCollectionPointsTable
                    data={collectionPointsData ? collectionPointsData.getCollectionPointsWithRegionID.entries : []}
                    selectedRow={Number(selectedStopPoint?.id)}
                    loading={collectionPointsLoading}
                    rowNumber={collectionPointsData?.getCollectionPointsWithRegionID.totalEntries || 0}
                    onRowClicked={(id: number) => {
                      if (id === selectedStopPoint?.id) {
                        setSelectedStopPoint(undefined)
                      } else {
                        const cp = collectionPointsData!.getCollectionPointsWithRegionID.entries.find((cp) => cp.id === String(id));
                        if(cp){
                          setSelectedStopPoint(mapCollectionPointConToStopInfo(cp))
                        }
                      }
                    }}
                    onPaginationChanged={() => setSelectedStopPoint(undefined)}
                    onSortingChanged={() => setSelectedStopPoint(undefined)}
                    materials={materialsData?.getMaterials || []}
                  />
                </Grid>
              </Grid>
            ) : (
              <Box>
                <Grid container direction="column">
                  <Grid item>
                  {data && (
                    <DisposalMerchantsStopTable
                      data={data.getDisposalMerchants}
                      districtId={districtId}
                      selectedStopPoint={selectedStopPoint}
                      setSelectedStopPoint={setSelectedStopPoint}
                      mapDisposalMerchantToStopInfo={mapDisposalMerchantToStopInfo}
                    />
                  )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item>
              <Button onClick={onClose} autoFocus variant="contained" color="inherit">
                {t("add_collection_point_dialog.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setConfirmOpen(true)}
                variant="contained"
                color="primary"
                disabled={lodash.isNil(selectedStopPoint)}
              >
                {t("add_collection_point_dialog.add")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
