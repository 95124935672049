import React from "react"
import { Text } from "../../wrapper/text"

interface IHeadingProps {
  children: String
}

export const PageHeading: React.FunctionComponent<IHeadingProps> = (props) => {
  const { children } = props
  return (
    <Text color="primary.main" variant={"h5"} bold>
      {children.toUpperCase()}
    </Text>
  )
}
