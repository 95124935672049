import { FunctionComponent, Fragment, useState, useMemo } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  TourExportPreviewCollectionPoint,
  TourExportPreviewMaterial,
} from "../../../../api/graphql/queries/tour-export-preview"
import { TourExportPreviewTable, CollectionPointStatus } from "./tour-export-preview-table"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { UserService } from "../../../../services/user-service"
import { ScheduleTourGenerationDialog } from "./schedule-tour-generation-dialog"
import {
  GET_TOUR_EXPORT_PREVIEW_AS_EXCEL_FILE_QUERY,
  GetTourExportPreviewAsExcelFileResult,
  GetTourExportPreviewAsExcelFileVariables,
} from "../../../../api/graphql/queries/get-tour-export-preview-as-excel-file"
import { useLazyQuery } from "@apollo/client"
import { ContentType, downloadBase64 } from "../../../../utils/browser"
import {
  TourExportPreviewCollectionPointInput,
  TourExportPreviewMaterialInput,
  AlgorithmType,
} from "../../../../api/graphql/graphql-global-types"

interface IConfirmTourGenerationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (scheduled?:boolean) => void
  date: Date | null
  loading: boolean
  collectionPoints: TourExportPreviewCollectionPoint[]
  materials: TourExportPreviewMaterial[]
  districtName: string
  isGenerateBtnDisabled: boolean
  thresholdMin: number | null
  algorithmType?: AlgorithmType
}

export const ConfirmTourGenerationDialog: FunctionComponent<IConfirmTourGenerationDialogProps> = (props) => {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onConfirm,
    date,
    loading,
    collectionPoints,
    materials,
    districtName,
    isGenerateBtnDisabled,
    thresholdMin,
    algorithmType
  } = props

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState<boolean>(false)

  const [getTourExportPreviewAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetTourExportPreviewAsExcelFileResult,
    GetTourExportPreviewAsExcelFileVariables
  >(GET_TOUR_EXPORT_PREVIEW_AS_EXCEL_FILE_QUERY, {
    onCompleted: (data) => downloadBase64(data.getTourExportPreviewAsExcelFile, ContentType.EXCEL),
    fetchPolicy: "no-cache",
  })
  const onGenerationConfirmed = (scheduled?: boolean) => {
    setConfirmDialogOpen(false)
    setScheduleDialogOpen(false)
    onConfirm(scheduled)
  }

  const mapCollectionPointsToCollectionPointInputs = (): TourExportPreviewCollectionPointInput[] =>
    collectionPoints.map((cp) => ({
      id: cp.id,
      location_number: cp.location_number,
      description: cp.description,
      street: cp.street,
      place: cp.place,
      fixed_unload_interval: cp.fixed_unload_interval,
      postal: cp.postal,
      town: cp.town,
      last_emptying: cp.last_emptying,
      materials: cp.materials.map((material) => ({
        filllevel: material.filllevel,
        filllevel_percentage: material.filllevel_percentage,
        max_amount: material.max_amount,
        material: { id: Number(material.material.id), name: material.material.name },
      })),
    }))

  const mapMaterialsToMaterialInputs = (): TourExportPreviewMaterialInput[] =>
    materials.map((material) => ({
      material: { id: Number(material.material.id), name: material.material.name },
      filllevel: material.filllevel,
    }))

  const isFirstDrivingDayInThePast = useMemo(() => {
    if (!date) return true
    const today = new Date()
    const firstDrivingDayDate = new Date(date)
    today.setHours(0, 0, 0, 0)
    firstDrivingDayDate.setHours(0, 0, 0, 0)

    return today > firstDrivingDayDate
  }, [date])

  return (
    <Fragment>
      <ConfirmDialog
        open={confirmDialogOpen}
        heading={t("tour_generation.confirm_dialog.confirm_title")}
        text={t("tour_generation.confirm_dialog.confirm_text")}
        confirmText={t("tour_generation.confirm_dialog.generate")}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => onGenerationConfirmed()}
      />
      <ScheduleTourGenerationDialog
        onClose={() => setScheduleDialogOpen(false)}
        open={scheduleDialogOpen}
        onConfirm={() => onGenerationConfirmed(true)}
        algorithmType={algorithmType || AlgorithmType.TO2}
      />
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <Grid container direction="column">
            <Grid item>{t("tour_generation.confirm_dialog.title")}</Grid>
            {!loading && !isFirstDrivingDayInThePast && (
              <Grid item container direction="row" justifyContent="space-between" sx={{ textAlign: "right" }}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("tour_generation.confirm_dialog.collection_point_info", {
                      count: collectionPoints.length || 0,
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  {materials?.map((material) => (
                    <Typography sx={{ fontWeight: "bold" }} key={`material${material.material.name}`}>
                      {material.material.name}:{" "}
                      {Math.round(material.filllevel).toLocaleString(UserService.getLanguage())}kg
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <Grid container direction="column">
            <Typography sx={{ fontWeight: "bold" }}>
              <Grid item>
                {!isFirstDrivingDayInThePast
                  ? t("tour_generation.preview_table.info_text", { thresholdMin })
                  : t("tour_generation.preview_table.past_days_container_view_info_text")}
              </Grid>
            </Typography>
            {!isFirstDrivingDayInThePast && (
              <Grid item>
                <TourExportPreviewTable
                  loading={loading}
                  collectionPoints={
                    collectionPoints.map((cp) => ({ ...cp, status: CollectionPointStatus.planned })) || []
                  }
                  materials={materials || []}
                  date={date}
                  showDiff={false}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item>
              <Button onClick={onClose} autoFocus variant="contained" color="inherit">
                {t("tour_generation.confirm_dialog.cancel")}
              </Button>
            </Grid>
            <Grid container item direction="row" spacing={2} justifyContent="flex-end" xs>
              <Grid item>
                <Button
                  onClick={() =>
                    getTourExportPreviewAsExcelFile({
                      variables: {
                        tour_export_preview: {
                          districtName: districtName,
                          collectionPoints: mapCollectionPointsToCollectionPointInputs(),
                          materials: mapMaterialsToMaterialInputs(),
                        },
                      },
                    })
                  }
                  variant="contained"
                  color="primary"
                  disabled={loading || excelLoading || isFirstDrivingDayInThePast}
                >
                  {excelLoading && <CircularProgress size={24} />}
                  {!excelLoading && t("tour_generation.confirm_dialog.export")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setScheduleDialogOpen(true)}
                  variant="contained"
                  color="primary"
                  disabled={loading || isGenerateBtnDisabled}
                >
                  {t("tour_generation.confirm_dialog.schedule")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => algorithmType === AlgorithmType.VROOM ? onGenerationConfirmed(): setConfirmDialogOpen(true)}
                  variant="contained"
                  color="primary"
                  disabled={loading || isGenerateBtnDisabled}
                >
                  {t("tour_generation.confirm_dialog.generate")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
