import React, { SyntheticEvent, useContext } from "react"
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { UserService } from "../../../../services/user-service"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { Clear } from "@mui/icons-material"

interface IHouseholdOverviewFilterProps {}

export const HouseholdOverviewFilter: React.FC<IHouseholdOverviewFilterProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { filter, updateFilter, address, setAddress, countyOptions, materialOptions, townOptions, resetFilter } =
    useContext(HouseholdOverviewFilterContext)

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={3} md={2}>
        <Card
          sx={{
            height: `calc(100% - ${theme.spacing(2)})`,
            maxHeight: `calc(100% - ${theme.spacing(2)})`,
            display: "flex",
            alignItems: "center",
            p: 1,
          }}
        >
          <RegionSelect
            filterAssociations={(entry) => entry.rfidModuleEnabled}
            filterDistricts={(entry) => entry.rfidModuleEnabled}
          />
        </Card>
      </Grid>
      <Grid item xs={9} md={10}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2} direction="row">
            <Grid item>
              <Typography sx={{ color: "primary.main" }} variant={"h5"}>
                {t("households.filter.heading")}
              </Typography>
            </Grid>
            <Grid container item xs alignItems="center" spacing={1}>
              {!UserService.hasAssociationFilter() && (
                <Grid item xs={6} md={4}>
                  {!!countyOptions.length && (
                    <CustomAutocomplete
                      id="households-filter-counties"
                      title={t("households.filter.counties")}
                      currentlySelectedValues={filter.selectedCounties}
                      availableSelectOptions={countyOptions}
                      setCurrentlySelectedValues={(newValues) =>
                        updateFilter({ ...filter, selectedCounties: newValues })
                      }
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={6} md={4}>
                <TextField
                  size="small"
                  id="outlined-search"
                  label={t("households.filter.address")}
                  type="search"
                  variant="outlined"
                  placeholder="Hauptstraße 5"
                  value={address}
                  onChange={(ev) => setAddress(ev.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4}>
                  <TextField
                    size="small"
                    label={t("households.filter.rfid")}
                    type="search"
                    variant="outlined"
                    placeholder="1234567890"
                    value={filter.rfid}
                    onChange={(ev) => {
                      updateFilter({ ...filter, rfid: ev.target.value })
                    }}
                    fullWidth
                  />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomAutocomplete
                  id="households-filter-materials"
                  title={t("households.filter.materials")}
                  currentlySelectedValues={filter.selectedMaterials}
                  availableSelectOptions={materialOptions}
                  setCurrentlySelectedValues={(newValues) => updateFilter({ ...filter, selectedMaterials: newValues })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomAutocomplete
                  id="households-filter-towns"
                  title={t("households.filter.towns")}
                  currentlySelectedValues={filter.selectedTowns}
                  availableSelectOptions={townOptions}
                  setCurrentlySelectedValues={(newValues) => updateFilter({ ...filter, selectedTowns: newValues })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.showWarnings}
                      onChange={(_event: SyntheticEvent, checked: boolean) =>
                        updateFilter({ ...filter, showWarnings: checked })
                      }
                      sx={{ color: "primary.light" }}
                      value={filter.showWarnings}
                    />
                  }
                  label={t("households.filter.show_warnings")}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip placement="top" title={t("households.filter.reset_filter") as string}>
                <IconButton onClick={resetFilter} size="small" sx={{ mt: 0.5 }}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
