import React, { FunctionComponent, Fragment, useEffect, useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { TourSelector } from "./tour-selector"
import { Grid, Card, CircularProgress, IconButton, Paper, CardContent, Typography, useTheme } from "@mui/material"
import { CardInfoHelper } from "../../../../utils/CardInfoHelper"
import { StopsTable } from "../../../partials/layout/table/stops-table"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import lodash from "lodash"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { UserService } from "../../../../services/user-service"
import { Edit } from "@mui/icons-material"
import { UserGroups } from "../../../../models/user-groups"
import { EditTourModal } from "./edit-tour-modal"
import { useTourOverview } from "../context/tour-overview-context"
import { TourOverviewMap } from "./tour-overview-map/tour-overview-map"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined"
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined"
import { TourDetail } from "./tour-detail"
import { TourHeader } from "./tour-header"
import moment from "moment"

interface ITourOverviewProps {}

export const TourOverview: FunctionComponent<ITourOverviewProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { selectedTour, tours} = useSelectedTour()
  const [isEditTourModalOpen, setIsEditTourModalOpen] = useState(false)

  const { selectedStopId, setSelectedStopId, tourStatsData, tourStatsError, tourStatsLoading, stopInfos } =
    useTourOverview()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const resetSelectedStopId = useCallback(() => {
    setSelectedStopId(undefined)
  }, [setSelectedStopId])

  useEffect(() => {
    resetSelectedStopId()
  }, [resetSelectedStopId])

  const actualDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.actual_duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.actual_duration)
        : null,
    [tourStatsData],
  )

  const plannedDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.duration)
        : null,
    [tourStatsData],
  )

  return (
    <Fragment>
      <EditTourModal
        open={!lodash.isEmpty(tourStatsData?.getTourStatsWithId?.stop_infos) && isEditTourModalOpen}
        onClose={() => setIsEditTourModalOpen(false)}
        stopInfos={tourStatsData?.getTourStatsWithId?.stop_infos || []}
        districtId={Number(tourStatsData?.getTourStatsWithId?.tour?.district_id)}
        date={tourStatsData?.getTourStatsWithId?.tour?.date}
        tourId={String(selectedTour?.id)}
      />
      <Grid sx={{ height: "calc(100vh - 56px)" }}>
        <Grid item>
          <ContentContainer sx={{ p: `0 ${theme.spacing(1)}`, flex: "0 0 auto", mb: 1 }}>
            <Card sx={{ p: 1 }}>
              <RegionSelect />
            </Card>
          </ContentContainer>
        </Grid>
        <Grid
          sx={{ p: `0 ${theme.spacing(1)}`, flex: "1 1 auto", overflowY: "auto", height: "100%" }}
          item
          container
          direction="row"
        >
          <Grid item sx={{ height: "100%" }}>
            <TourSelector />
          </Grid>
          <Grid
            sx={{
              padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
              overflowY: "auto",
              width: "100%",
              height: "100%",
              flex: "1",
            }}
            item
          >
            <Grid container direction="column">
              {tourStatsLoading && (
                <Grid container item justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              )}
              {tourStatsData && !tourStatsError && !tourStatsLoading && selectedTour && (
                <Fragment>
                  <TourHeader />
                  <Grid container justifyContent="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={7}>
                      <TourOverviewMap />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container spacing={1} sx={{ height: "100%" }}>
                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate && (
                          <Grid item xs={6}>
                            <Paper variant="outlined" sx={{ borderRadius: "16px", height: "60px", padding: "2px" }}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <LocalShippingOutlinedIcon sx={{ fontSize: "10", marginTop: "3px" }} />
                                  </Grid>
                                  <Grid container item xs direction="column" justifyContent="center">
                                    <Grid item>
                                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography sx={{ fontSize: "10px", marginTop: "-5px" }}>
                                        {" "}
                                        {t("Truck")}{" "}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Paper variant="outlined" sx={{ borderRadius: "16px", height: "60px", padding: "2px" }}>
                            <CardContent>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item>
                                  <TodayOutlinedIcon sx={{ fontSize: "10", marginTop: "3px" }} />
                                </Grid>
                                <Grid container item xs direction="column">
                                  <Grid item>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 12 }}>
                                      {` ${moment(selectedTour.date).format(t("date_format"))}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          {plannedDuration && (
                            <Paper variant="outlined" sx={{ borderRadius: "16px", height: "60px", padding: "2px" }}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <QueryBuilderOutlinedIcon sx={{ fontSize: "10", marginTop: "3px" }} />
                                  </Grid>
                                  <Grid item xs={4} container alignItems="center">
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                      {plannedDuration}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      &nbsp;{t("tour_overview.planned_duration")}
                                    </Typography>
                                  </Grid>
                                  <Grid item sx={{ fontWeight: "bold", fontSize: 14 }}>
                                    |
                                  </Grid>
                                  <Grid item xs container alignItems="center">
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                      {actualDuration ? actualDuration : "--"}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      &nbsp;{t("tour_overview.actual_duration")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ height: "100%" }}>
                          <TourDetail />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="flex-start">
                    <Grid item>
                      <Typography sx={{ fontSize: "20px", marginTop: "10px", fontWeight: "bold", color: "grey" }}>
                        {t("tour_overview.collection_points")}
                      </Typography>
                    </Grid>
                    {UserService.isInUserGroup(UserGroups.TOUR_MANAGEMENT) && (
                      <Grid item>
                        <IconButton onClick={() => setIsEditTourModalOpen(true)}>
                          <Edit color="primary" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item>
                    <StopsTable
                      tourId={selectedTour?.id}
                      isInEditMode={false}
                      onRowClicked={(id: number) => {
                        if (id === selectedStopId) {
                          setSelectedStopId(undefined)
                        } else {
                          setSelectedStopId(id)
                        }
                      }}
                      stopInfos={stopInfos}
                      selectedStopId={selectedStopId}
                    />
                  </Grid>
                </Fragment>
              )}

              {tours && tours.length === 0 && (
                <CustomSnackBarContent
                  sx={{ mt: 1 }}
                  variant="info"
                  message={t(
                    `errors.no_${UserService.hasAssociationFilter() ? "association" : "district"}_tours_found`,
                  )}
                />
              )}

              {tours && tours.length > 0 && selectedTour === undefined && (
                <CustomSnackBarContent sx={{ mt: 1 }} variant="info" message={t("tour_selector.choose")} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
